import React from "react";
import { TailSpin } from "react-loader-spinner";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .loader-class {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Loader = () => {
  return (
    <StyledWrapper>
      <div className="loader-class">
        <TailSpin height="45" width="100" color="#F2B842" ariaLabel="loading" />
      </div>
    </StyledWrapper>
  );
};

export default Loader;
